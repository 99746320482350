<script lang='ts'>
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import UserBadge from '@/components/forum/UserBadge.svelte'
  import IconInfo from "@/components/icons/phosphor/IconInfo.svelte";
  import IconPencilSimple from "@/components/icons/phosphor/IconPencilSimple.svelte";
  import Overlay from '@/components/ui/Overlay.svelte'

  import { RevisionType } from '@/definitions/forum/common'

  type Revision = {
    content: string,
    created_at: string,
    id: number,
    id_forum_topic?: number,
    id_language: number,
    id_user: number,
    revision_details: string,
    updated_at: string
  }

  type RevisionDetails = {
    avatar: string,
    content: string,
    created_at: string,
    id: number,
    id_language: number,
    id_user: number,
    nick: string
  }
  type RevisionDetails2 = {
    data: Record<string, string>,
    downvotes: Record<string, string>[],
    numOfLikes: number,
    revisions: Revision[],
    translationRevisions: Record<string, string>[],
    translations: Record<string, string>[]
  }

  export let revision: Revision,
    type: RevisionType = 'reply',
    user = { is_admin: false }

  let showDetailsPopup = false,
    showRevisionsPopup = false,
    details: RevisionDetails2,
    revisionDetails: RevisionDetails[] = []

  let rev: Revision
  let revs: RevisionDetails[] = []
  let det: RevisionDetails[] = []
  $: if (revision) {
    rev = revision
    det = rev.revision_details ? JSON.parse(rev.revision_details) : []
    const uniqueUsers = [...new Set(det.map(r => r.id_user).filter((id: number) => id !== rev.id_user))]
    // create edited by revisions array
    if (uniqueUsers.length > 0) {
      revs = uniqueUsers.map((id: number) => det.find(r => r.id_user === id)).filter(r => r) as RevisionDetails[]
    }
  }

  const loadData = async (type: RevisionType) => {
    showDetailsPopup = true
    const data = await fetchData('forum/details', { id: revision.id, type })
    details = {
      data: data.data || [],
      downvotes: data.downvotes || [],
      numOfLikes: data.numOfLikes || 0,
      revisions: data.revisions || [],
      translationRevisions: data.translationRevisions || [],
      translations: data.translations || []
    }
  }
  const loadRevisionData = async (type: RevisionType) => {
    showRevisionsPopup = true
    const data = await fetchData('forum/revisionDetails', { id: revision.id, language: revision.id_language, type })
    revisionDetails = Object.values(data.data || {}) || []
  }
</script>
{#if user.is_admin && ['reply', 'topic'].includes(type)}
  <span
    class='kebab-item'
    role='button'
    tabindex='0'
    on:click={(e) => { e.stopPropagation(); loadData(type) }}
    on:keypress={() => {}}
  >
    <span class='icon-wrapper'><IconInfo /></span> <span class='content'>Info</span>
  </span>
  {#if showDetailsPopup && details.data}
    <Overlay absoluteCloseButton onClick={() => { showDetailsPopup = false }}>
      <h2>{type} details</h2>
      <div class='details-table'>
        {#each Object.keys(details.data) as d}
          {#if d !== 'content'}
            <div class='th'>{d.replace(/_/g, ' ')}</div>
            <div>{details.data[d]}</div>
          {/if}
        {/each}
      </div>
      <h2>{$_('mix.translations')}</h2>
      <div class='translations'>
        <table>
          <tr>
            <th>{$_('revision.id')}</th>
            <th>{$_('revision.idTopic')}</th>
            <th>{$_('revision.language')}</th>
            <th>{$_('revision.idUser')}</th>
            <th>{$_('mix.translation')}</th>
            <th>{$_('revision.score')}</th>
            <th>{$_('revision.idTask')}</th>
            <th>{$_('revision.count')}</th>
            <th>{$_('revision.details')}</th>
            <th>{$_('revision.created')}</th>
            <th>{$_('revision.updated')}</th>
          </tr>
          {#each details.translations as d}

            <tr>
              <td>{d.id}</td>
              <td>{d.id_forum_topic}</td>
              <td>
                <Flag id={toInt(d.id_language)} />
              </td>
              <td>{d.id_user}</td>
              <td>{d.translation}</td>
              <td>{d.score}</td>
              <td>{d.id_task}</td>
              <td>{d.revision_count}</td>
              <td>{d.revision_details}</td>
              <td>{d.created_at}</td>
              <td>{d.updated_at}</td>
            </tr>
          {/each}
        </table>
      </div>
      {#if details.revisions.length}
        <h2>{$_('revision.updated')}Revisions</h2>
        <table>
          <tr>
            <th>{$_('revision.id')}</th>
            <th>{$_('revision.idTopic')}</th>
            <th>{$_('revision.idUser')}</th>
            <th>{$_('revision.content')}</th>
            <th>{$_('revision.created')}</th>
          </tr>
          {#each details.revisions as d}
            <tr>
              <td>{d.id}</td>
              <td>{d.id_forum_topic}</td>
              <td>{d.id_user}</td>
              <td>{d.content}</td>
              <td>{d.created_at}</td>
            </tr>
          {/each}
        </table>
      {/if}
      {#if details.translationRevisions.length}
        <h2>{$_('revision.translations')}</h2>
        {#each details.translationRevisions as d}
          <div>{JSON.stringify(d)}</div>
        {/each}
      {/if}
      {#if details.downvotes.length}
        <h2>{$_('revision.downvotes')}</h2>
        <table>
          <tr>
            <th>{$_('revision.id')}</th>
            <th>{$_('revision.idTopic')}</th>
            <th>{$_('revision.idReply')}</th>
            <th>{$_('revision.rating')}</th>
            <th>{$_('revision.reason')}</th>
            <th>{$_('revision.idUser')}</th>
            <th>{$_('revision.idReceiver')}</th>
            <th>{$_('revision.created')}</th>
            <th>{$_('revision.updated')}</th>
          </tr>
          {#each details.downvotes as d}
            <tr>
              <td>{d.id}</td>
              <td>{d.id_topic}</td>
              <td>{d.id_reply}</td>
              <td>{d.rating}</td>
              <td>{d.reason}</td>
              <td>{d.id_user}</td>
              <td>{d.id_receiver}</td>
              <td>{d.created_at}</td>
              <td>{d.updated_at}</td>
            </tr>
          {/each}
        </table>
      {/if}
      {$_('revision.likeNumber')}: {details.numOfLikes}
    </Overlay>
  {/if}
{/if}
{#if det.length}
  <div class='revisions-wrapper'>
    <div>
      <small
        role='button'
        tabindex='0'
        on:click={() => { loadRevisionData(type) }}
        on:keypress={() => {}}
      >{det.length}
        <IconPencilSimple />
      </small>
      {#if showRevisionsPopup}
        <Overlay absoluteCloseButton onClick={() => (showRevisionsPopup = false)}>
          {#if revisionDetails.length}
            <h2>{$_('forum.revisions')} {revisionDetails.length}</h2>
            {#each revisionDetails as d}
              <div class='revision-heading'>
                <a href='/profile/{d.id}' use:link>{d.nick} <img alt='' src={d.avatar} width='30' /></a> {d.created_at}
              </div>
              <div>{@html d.content}</div>
            {/each}
          {/if}
        </Overlay>
      {/if}
    </div>

    {#if revs.length}
      {#each revs as revi}
        <UserBadge user={revi} />
      {/each}
    {/if}
  </div>
{/if}
<style lang='scss'>
  .kebab-item {
    cursor: pointer;
  }

  .revisions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    :global(.image) {
      display: none;
    }
  }

  .details-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    > .th {
      padding: 0.5rem;
      font-weight: bold;
      text-align: left;
      background: var(--Gray-Light);
      border-right: 0.1rem solid var(--Gray-Medium);
      border-bottom: 0.1rem solid var(--Gray-Medium);
    }
  }

  .revision-heading {
    padding: 1rem;
    color: var(--inverted-text-color);
    background: var(--Primary-Medium);

    > a {
      color: var(--inverted-text-color);
    }
  }

  @media (max-width: 768px) {
    .revisions-wrapper {
      order: 4;
    }
  }
</style>
