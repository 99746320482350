<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { link } from 'svelte-routing'

  import { UI_VISIBLE_LANGUAGES } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { reorderLanguages } from '@/helpers/forumHelpers'
  import { clickOutside, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Flag from '@/components/forum/Flag.svelte'
  import IconCaretDown from "@/components/icons/phosphor/IconCaretDown.svelte";
  import IconTranslate from "@/components/icons/phosphor/IconTranslate.svelte";
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { LanguageGroups } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'
  import { forumConfig } from '@/store'

  export let category: number = 0,
    languages: number[] = [],
    clickOnFlag = (language: number, category: number, subcategory: number) => {
    },
    missingTranslations: number[] = [],
    active = 0,
    subCategory = 0

  let showDropdown = false,
    translationLanguage = 0,
    showTranslationForm = false,
    title = '',
    content = '',
    translationSent = false,
    original = { content: '', title: '' }

  const MIN_REQUIRED_TITLE_LENGTH = 3
  const translationCategori = subCategory || category

  let firstTwoLanguages = languages.slice(0, UI_VISIBLE_LANGUAGES)
  let otherLanguages = languages.slice(UI_VISIBLE_LANGUAGES)
  const unsubscribe = forumConfig.subscribe((config: LanguageGroups) => {
    if (config.known.length) {
      languages = reorderLanguages(languages, config.known.map(el => toInt(el.lang)))
      firstTwoLanguages = languages.slice(0, UI_VISIBLE_LANGUAGES)
      otherLanguages = languages.slice(UI_VISIBLE_LANGUAGES)
    }
  })

  let showOtherLangs = false

  const selectTranslationLanguage = (id: number) => {
    translationLanguage = id
    showTranslationForm = true
    translateCategoryLoad()
  }

  function translateCategoryLoad () {
    fetchData('forum/translateCategoryLoad', { categoryId: translationCategori, language: translationLanguage }).then(data => {
      original = { content: data.content, title: data.title }
    })
  }

  function saveTranslation () {
    if (title.length < MIN_REQUIRED_TITLE_LENGTH) {
      notifier.error($_('forum.titleTooShort'))
      return
    }
    // eslint-disable-next-line sort-keys
    fetchData('forum/translateCategorySave', { categoryId: translationCategori, content, language: translationLanguage, title }).then(() => {
      translationSent = true
    })
  }

  onDestroy(() => {unsubscribe()})
</script>
<div class='languages-wrapper'>
  {#if missingTranslations?.length}
    <div class='add-missing-translation'>
      <FakeButton onClick={() => { showDropdown = !showDropdown }} title={$_('forum.addTranslation')}>
        {#if missingTranslations.length}
          <IconTranslate size='18' weight='regular' />
          <span>+</span>
        {/if}
      </FakeButton>
      <div
        class='langs-list'
        class:-hidden={!showDropdown}
        on:click_outside={() => { showDropdown = false }}
        use:clickOutside
      >
        {#each missingTranslations as language}
          <Flag id={language} clickOnFlag={selectTranslationLanguage} />
        {/each}
        {#if showTranslationForm}
          <Overlay absoluteCloseButton onClick={() => {showTranslationForm = false}}>
            <div class='translate-category'>
              <h2>{$_('forum.translateCategory')}</h2>
              <hr />
              <div class='original-and-translatio'>
                <div class='original-content'>
                  <h5>{$_('mix.original')}:</h5>
                  <h2>{original.title}</h2>
                  <span>{original.content}</span>
                </div>
                {#if translationSent}
                  {$_('forum.translationSent')}
                  <a href='/forum' use:link>{$_('forum.goHome')}</a>
                {:else}
                  <form class='translation-content' on:submit|preventDefault={saveTranslation}>
                    <h5>{$_('mix.translation')}</h5>
                    <input id='title' placeholder={$_('forum.translateHeading')} required type='text' bind:value={title} />
                    <textarea id='content' cols='30' placeholder={$_('forum.translateDescription')} rows='3' bind:value={content} />
                    <input type='submit' value={$_('forum.saveTranslation')} />
                  </form>
                {/if}
              </div>
            </div>
          </Overlay>
        {/if}
      </div>
    </div>
  {/if}
  <span class='verticalLine' />
  <div class='flags-wrapper'>
    {#each firstTwoLanguages as language, index(language)}
      <Flag
        id={language}
        active={active === language}
        clickOnFlag={(lang) => clickOnFlag(lang, category, subCategory)}
      />
    {/each}
    {#if otherLanguages?.length}
      <div class='other-langs-wrapper'>
        <FakeButton onClick={() => { showOtherLangs = !showOtherLangs }} title={$_('mix.showMore')}>
          <span>+ {otherLanguages.length}</span>
          <IconCaretDown size='12' weight='bold' />
        </FakeButton>
        <div
          class='langs-list'
          class:-hidden={!showOtherLangs}
          on:click_outside={() => { showOtherLangs = false }}
          use:clickOutside
        >
          {#each otherLanguages as language, index(language)}
            <Flag
              id={language}
              active={active === language}
              clickOnFlag={(lang) => clickOnFlag(lang, category, subCategory)}
            />
          {/each}
        </div>
      </div>
    {/if}
  </div>
</div>
<style lang='scss'>
  .languages-wrapper {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    > .verticalLine {
      height: 2.4rem;
      border-right: 0.1rem solid var(--Gray-Light);
    }
  }

  .flags-wrapper {
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .add-missing-translation, .other-langs-wrapper {
    position: relative;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: var(--Gray-Light);
    border-radius: 1.6rem;

    > :global(.fake-button) {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
    }
  }

  .langs-list.-hidden {
    display: none;
  }

  .translate-category {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .original-and-translatio {
    display: flex;
    gap: 2.4rem;
  }

  .original-content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    max-width: 50%;

    > h2, > span {
      padding: 0.8rem 1.2rem;
      font: var(--Regular-400);
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 0.8rem;
    }
  }

  .translation-content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 50%;

    > input, > textarea {
      padding: 0.8rem 1.2rem;
    }
  }

  @media (max-width: 768px) {
    .languages-wrapper {
      margin-bottom: 0.5rem;
    }
  }
</style>
